<template>
  <div class="w-full h-full">
    <div class="grid-container scene-tree h-full flex-1 z-40">
      <div class="bg-gray-700">
        <SceneGraphNodeDetail
          :key="node.id"
          :node="node"
          :gui="gui"
          @close="$emit('close')"
        />
      </div>
    </div>
  </div>
</template>
<script>

const SceneGraphNodeDetail = () => import('./scenegraph/SceneGraphNodeDetail')

export default {
  components: {
    SceneGraphNodeDetail
  },

  props: {
    gui: {
      type: Object,
      required: true
    },
    node: {
      type: Object,
      required: true
    }
  }
}
</script>
